"use client";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import BASE_URL from "@/components/constants";
import { Logout } from "@/components/auth/Logout";
import { useAuth } from "@/context/AuthContext";
import { toast } from "react-hot-toast";
import { usePathname } from "next/navigation";
import getToken from "@/hook/getToken";
const Cart = dynamic(() => import("@/components/cart"));
const Signin = dynamic(() => import("@/components/auth/Signin"));
const Wishlist = dynamic(() => import("@/components/wishlist"));
const Search = dynamic(() => import("./Search"));
const NavLinks = dynamic(() => import("@/components/layout/navbar/NavLinks"));
const Sidebar = dynamic(() => import("./SIdebar"));
const Navbar = () => {
  const pathname = usePathname();
  const [isClicked, setIsClicked] = useState(false);
  const { isSignInOpen, setIsSignInOpen } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isBelowHero, setIsBelowHero] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleSignInClick = () => {
    setIsSignInOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getToken();
        if (!accessToken) return;
        // Replace with your actual access token
        const response = await fetch(`${BASE_URL}/v1/auth/b2c/me`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          let userName;

          if (data.userDetails.name) {
            userName = data.userDetails.name;
          } else {
            // Hardcoded username if name is not available
            userName = "User";
          }

          setUserName(userName);
        } else {
          localStorage.removeItem("access_token");
          console.error("Failed to fetch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [isSignInOpen]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if scrolled below hero section (you might need to adjust the value accordingly)
      if (window.scrollY < 200) {
        setIsBelowHero(true);
      } else {
        setIsBelowHero(false);
      }
    };

    // Add event listener for window scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      className={`mx-auto max-w-screen-2xl sticky top-0 z-30 shadow-md ${isBelowHero && pathname == "/"
        ? "bg-transparent transition-colors duration-500"
        : " bg-white transition-colors duration-500"
        }`}
    >
      <Signin isSignInOpen={isSignInOpen} />

      <div className="lg:flex justify-between hidden items-center px-5 py-2">
        <div>
          {isLargeScreen ? (
            <div onClick={() => setIsClicked(true)} >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="md:block sm:hidden">
          <Link href="/">
            <img
              className="w-10 h-10 "
              src="https://res.cloudinary.com/genx21/image/upload/v1702298803/home/dpmuqj07lb9sxrspfnwe.png"
              alt="x"
            />
          </Link>
        </div>

        <div className="w-2/5">
          <Search pathname={pathname} isBelowHero={isBelowHero} />
        </div>
        <div className="flex gap-6 items-center">
          <div
            className={`${pathname.startsWith("/checkout") ? "hidden" : "block"
              } `}
          >
            <Link href={`/user/wishlist`}>
              <Wishlist />
            </Link>
          </div>
          <div
            className={`${pathname.startsWith("/checkout") ? "hidden" : "block"
              }`}
          >
            <Cart />
          </div>
          <div className="relative">
            {userName ? (
              <div
                className="mx-1 border-[#1D1D1F] capitalize px-2 py-1.5 text-sm font-medium"
                onMouseEnter={handlePopupOpen}
                onMouseLeave={handlePopupClose}
              >
                <span className="cursor-pointer">
                  {userName === undefined
                    ? "User"
                    : `Hi, ${userName}` || "Hi,User"}
                </span>
                {isPopupOpen && (
                  <div
                    className="absolute top-7 right-0 bg-white border border-gray-300 p-2 z-10"
                    onMouseEnter={handlePopupOpen}
                    onMouseLeave={handlePopupClose}
                  >
                    <button className="block w-full text-left mb-2">
                      <Link href="/user">My Account</Link>
                    </button>
                    <button
                      className="block w-full text-left"
                      onClick={() => toast.success("Logout successful")}
                    >
                      <Logout />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="mx-1 rounded-md border-2 border-[#1D1D1F] px-4 py-1.5 text-sm font-medium cursor-pointer"
                onClick={handleSignInClick}
              >
                Sign In
              </div>
            )}
          </div>
        </div>
      </div>

      {isClicked && (
        <NavLinks isClicked={isClicked} setIsClicked={setIsClicked} />
      )}

      {isLargeScreen ? null : <Sidebar />}
    </nav>
  );
};

export default Navbar;