import React, { useEffect, useState } from 'react'
import { useCart } from "@/context/cart/CartContext";
import CouponItem from './CouponItem'
import BASE_URL from '../constants'
import getToken from '@/hook/getToken'
const CouponsModal = ({ isVisible, onClose, buyNow }) => {
    const { setCouponId } = useCart()
    const [couponList, setCouponList] = useState([])
    const [appliedCoupon, setAppliedCoupon] = useState('')
    const [error, setError] = useState('')
    const getCoupons = async (url, token) => {
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}` // Assuming your token is a bearer token
                }
            });
            const data = response.json()
            return data;
            // Handle the response here
        } catch (error) {
            // Handle errors here
            console.log('Error :', error);
        }
    }

    useEffect(() => {
        const apiUrl = buyNow ? `${BASE_URL}/v1/coupon/getCouponAsPerCart/b2c?buy_now=true` : `${BASE_URL}/v1/coupon/getCouponAsPerCart/b2c`;
        const token = getToken()
        getCoupons(apiUrl, token).then(data => {
            setCouponList(data.coupons)
        }).catch(err => console.log('fetch-coupons', err))
    }, [])

    const handleApply = (id, couponName) => {
        setAppliedCoupon(couponName)
        setCouponId(id)
        onClose(couponName)
    }

    const handleClose = (e) => {
        const { id } = e.target;
        if (id === 'coupon-modal') {
            onClose()
        }
    }
    if (!isVisible) {
        return null
    }

    const handleApplyCoupon = () => {
        const coupon = couponList.find(item => item.coupon_code === appliedCoupon)
        if (coupon) {
            setCouponId(coupon._id)
            onClose(appliedCoupon)
        } else {
            setError('Invalid Coupon')
        }
    }
    return (
        <div
            onClick={(e) => handleClose(e)}
            id='coupon-modal'
            className='fixed text-xs inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-40 flex justify-center items-center'>

            <div className=' bg-white rounded-md p-4 mx-4'>
                <div className='flex flex-col '>
                    <div className='sticky top-0 z-50 backdrop:blur-sm '>
                        <div className='flex justify-between items-center'>
                            <p className='text-lg text-[#3D4152]font-bold'>Available Coupons</p>
                            <p onClick={() => onClose()} className='p-2 text-2xl text-[#3D4152] cursor-pointer'>X</p>
                        </div>
                        {/* //input section for coupon code  */}
                        <div className='flex flex-row my-5 border border-solid border[#A9ABB2] '>
                            <input
                                className='w-full md:w-60 py-3 px-4 outline-none placeholder:text-[#A9ABB2]'
                                type='search'
                                placeholder='Enter coupon code'
                                onChange={(e) => setAppliedCoupon(e.target.value)}
                                value={appliedCoupon}
                            />
                            <button
                                onClick={() => handleApplyCoupon()}
                                className='bg-black w-40 flex justify-center items-center text-sm text-white font-bold py-3 px-4' >Apply</button>
                        </div>
                        {error && <p className='text-red-500 text-xs text-center pb-2'>{error}</p>}
                    </div>
                    {/* // Available Coupons */}
                    {couponList?.length ?
                        <div className='py-5 flex flex-col space-y-4 border border-solid border[#A9ABB2] max-h-[20rem] overflow-scroll no-scrollbar'>
                            {
                                couponList?.map((item, index) => (
                                    <CouponItem
                                        key={item._id}
                                        couponName={item.coupon_code}
                                        desc={item.description}
                                        id={item._id}
                                        handleApply={handleApply}
                                    />
                                ))
                            }
                        </div>
                        : <h1 className='text-center'>No Coupons Available</h1>
                    }
                </div>
            </div>
        </div>
    )
}

export default CouponsModal