'use client'
import React, { useState, useCallback } from 'react';
import CouponsModal from './CouponsModal';
import { useCart } from "@/context/cart/CartContext";
const Coupons = ({ buyNow }) => {
    const { setCouponId } = useCart()
    const [isCouponClicked, setIsCouponClicked] = useState(false);
    const [appliedCoupon, setAppliedCoupon] = useState('')
    const handleClose = useCallback((couponName) => {
        setIsCouponClicked((preValue) => !preValue);
        setAppliedCoupon(couponName)
    }, []);

    const handleClear = () => {
        setCouponId('')
        setAppliedCoupon('')
    }

    return (
        <div className='flex flex-col'>
            <div
                onClick={() => setIsCouponClicked(true)}
                className="h-fit  flex flex-col py-2 px-4 border border-dashed border-[#A9ABB2] cursor-pointer"
            >
                <div className='w-52 flex flex-row gap-x-4 text-[#535665]'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 text-[#535665]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>
                    </div>
                    Apply Coupon
                </div>
            </div>
            <div className=' my-4'>
                {
                    appliedCoupon ?
                        <button className='flex gap-x-2 items-center py-1 px-6 rounded bg-[#0E3174] text-white'>{appliedCoupon} <span
                            onClick={() => handleClear()}
                            className='text-xl  font-bold '>X</span></button>
                        : null
                }
            </div>
            {isCouponClicked ? (
                <CouponsModal
                    buyNow={buyNow}
                    isVisible={isCouponClicked}
                    onClose={handleClose}
                />
            ) : null}
        </div>
    );
}

export default Coupons;
