"use client";
import React from "react";
import ScrollToTop from "react-scroll-up";
const ScrollToTopButton = () => {
    return (
        <div className="relative z-20 ">
            <ScrollToTop showUnder={350}>
                <div className="p-3 rounded-full bg-white shadow">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        version="1.2"
                        id="up-arrow"
                    >
                        <path d="M12 21c-1.654 0-3-1.346-3-3v-4.764c-1.143 1.024-3.025.979-4.121-.115a3.002 3.002 0 0 1 0-4.242L12 1.758l7.121 7.121a3.002 3.002 0 0 1 0 4.242c-1.094 1.095-2.979 1.14-4.121.115V18c0 1.654-1.346 3-3 3zM11 8.414V18a1.001 1.001 0 0 0 2 0V8.414l3.293 3.293a1.023 1.023 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L12 4.586l-5.707 5.707a.999.999 0 0 0 0 1.414 1.023 1.023 0 0 0 1.414 0L11 8.414z"></path>
                    </svg>
                </div>
            </ScrollToTop>
        </div>
    );
};

export default ScrollToTopButton;