'use client'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import BASE_URL from './constants'
import { HoverBorderGradient } from './ui/hover-border-gradient'
const Subscription = () => {
    const [email, setEmail] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        const apiUrl = `${BASE_URL}/v1/subscription/subscribe`
        const postData = {
            email
        }; // Replace this with your actual data

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData) // Convert your data to JSON format
        };

        try {
            const res = await fetch(apiUrl, requestOptions);
            if (res.ok) {
                localStorage.setItem('subscribed', true)
                toast.success("Subcribed Successfully")
                setEmail('')
            }
            if (res.status == '400') {
                toast.error("Already Subscribed")
            }
            // Handle the response here
        } catch (error) {
            // Handle any errors
            console.error('Error:', error);
        }
    }
    return (
        <form onSubmit={(e) => handleSubmit(e)} >
            <div className='flex flex-col gap-2 sm:flex-row '>
                <input
                    type='email'
                    required
                    value={email}
                    placeholder='Enter Your Email Address'
                    onChange={(e) => setEmail(e.target.value)}
                    className='w-full py-2 px-4 border-b  border-white outline-none bg-transparent'
                />

                <HoverBorderGradient
                    containerClassName="rounded-full"
                    as="button"
                    className="bg-black  text-white flex items-center space-x-2"
                >
                    <span>Subscribe</span>
                    <SubscribeLogo />
                </HoverBorderGradient>

            </div>
        </form>
    )
}

export default Subscription

const SubscribeLogo = () => {
    return (
        <span className='ms-2 pb-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
        </span>
    );
};
