import React from "react";
import DeleteItemButton from "./DeleteItemButton";
import QtyChangeOption from "../checkout/QtyChangeOption";
import Link from "next/link";
const CartItem = ({ item, onClose }) => {
  const { _id, image, order_qty, pricing, product, product_id } = item;
  const { qty_available } = pricing;
  const redirectLink = `/product/${product?.item_slug}/buy/${product_id}`
  return (
    <div>
      <li className="flex py-4">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
          <Link onClick={() => onClose()} href={redirectLink}>
            <img
              src={image?.main_img}
              alt={product?.item_name}
              className="h-full w-full object-contain object-center"
            />
          </Link>
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between text-base font-medium text-gray-900">
              <h3>
                <Link
                  onClick={() => onClose()}
                  href={redirectLink}
                  className="hover:text-blue-500"
                >
                  {product?.item_name}
                </Link>
              </h3>
              <p className="ml-4 whitespace-nowrap">₹ {pricing?.list_price}</p>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              {product?.product_description?.slice(0, 20)}
            </p>
          </div>
          <div className="flex flex-1 items-center justify-between text-sm pt-1">
            <QtyChangeOption
              qty_available={qty_available}
              order_qty={order_qty}
              id={_id}
              style={"w-9 h-7"}
            />

            <DeleteItemButton id={_id} />
          </div>
        </div>
      </li>
      <div className="flex justify-between  bg-gray-100 text-sm px-2  py-1 w-full">
        <Link onClick={() => onClose()} href="/product">
          <p>Include more</p>
        </Link>
        <Link onClick={() => onClose()} href="/product">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default CartItem;
