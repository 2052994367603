'use client';
import React from 'react';
import Subscription from '../Subscription';
import Link from 'next/link';
import MobileFooter from '../MobileFooter';
import { useMediaQuery } from '@react-hook/media-query';

function Footer() {
  const currentYear = new Date().getFullYear()
  const mobileView = useMediaQuery("(max-width: 768px)")

  // Array of footer links
  const footerLinks = [
    {
      title: "Company",
      links: [
        { label: "Who we are", url: "/about" },
        { label: "Career", url: "#" },
        { label: "Blog", url: "/blog" }
      ]
    },
    {
      title: "Category",
      links: [
        { label: "3c", url: "/3c-products" },
        { label: "Lifestyle", url: "/lifestyle" },
        { label: "Home & Kitchen", url: "/home-kitchen" },
        { label: "Office", url: "/office-product" },
        { label: "Babies & Kids", url: "/baby-kids" }
      ]
    },
    {
      title: "Help Center",
      links: [
        { label: "Contact Us", url: "/contact" },
        { label: "Cancellation & Refund ", url: "/refund" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Warranty Policy ", url: "/warranty-policy" },
        { label: "Terms of use", url: "/terms-conditions" },
        { label: "Shipping Policy", url: "/shipping-policy" }
      ]
    }
  ];
  if (mobileView) {
    return <MobileFooter />
  }

  return (
    <footer className="block bg-white mt-10 border-t  bg-no-repeat bg-center"
      style={{
        backgroundImage: "url('/footer.jpg')"
      }}
    >
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className={`md:flex md:justify-between relative `}

        >
          <div className='hidden md:block absolute top-[90%] left-0 '>
            <Subscription />
          </div>
          <div className="mb-6 md:mb-0 flex flex-col justify-center items-center ">
            <a href="https://21genx.com/" className="flex justify-center items-center ">
              <img src="https://res.cloudinary.com/genx21/image/upload/v1712051256/a18uecqmoowbuz1hhhzl.png" className="w-52 h-52 me-3" alt="21Genx" />
            </a>

          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            {footerLinks.map((section, index) => (
              <div key={index}>
                <h2 className="mb-6 text-sm font-semibold text-white text-opacity-80 uppercase ">{section.title}</h2>
                <ul className="text-gray-400  font-medium">
                  {section.links.map((link, index) => (
                    <li key={index} className="mb-2">
                      <Link href={link.url} className="hover:underline">{link.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-gray-400 sm:text-center ">© {currentYear} <a href="https://flowbite.com/" class="hover:underline">21Genx</a>. All Rights Reserved.
          </span>
          <div class="flex mt-4 sm:justify-center sm:mt-0">
            <a target='_blank' href="https://www.facebook.com/profile.php?id=61558072941317" className="text-gray-400 hover:text-gray-500 ">
              <svg className="w-4 h-4" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only">Facebook page</span>
            </a>

            <a
              target="_blank"
              href="https://www.instagram.com/21genx/"
              className="text-gray-400 hover:text-gray-500  ms-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
              <span className="sr-only">Instagram</span>
            </a>

            <a
              target="_blank"
              href="https://twitter.com/21GenX"
              className="text-gray-400 hover:text-gray-500  ms-5"
            >
              <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path fill="currentColor" d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z" />
              </svg>
              <span className="sr-only">Twitter page</span>
            </a>
            <a target='_blank' href="https://in.pinterest.com/21genx/" class="text-gray-400 hover:text-gray-500  ms-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </svg>

            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/21genx/"
              className="text-gray-400 hover:text-gray-500  ms-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path
                  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
              <span className="sr-only">LinkedIn</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
