import React from 'react'

const CouponItem = ({ couponName, desc, id, handleApply }) => {

    return (
        <div className='flex flex-col px-4'>
            <div className='w-fit py-2 px-4 flex bg-[#FFFAE6] border border-[#DACEB7]'>
                <img className='w-5 h-5 object-cover' src='/assets/image/coupon-logo.png' alt='logo' />
                <p className='px-4 ml-2 text-sm'> {couponName}</p>
            </div>
            <div className='py-2'>
                <p className='max-w-xs text-xs text-[#686B78]'>{desc}</p>
            </div>
            <button
                onClick={() => handleApply(id, couponName)}
                className='text-[#6691E7] font-bold py-2 px-4 border border-[#6691E7] hover:scale-105'>
                Apply Coupon
            </button>
        </div>
    )
}

export default CouponItem